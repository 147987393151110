import _ from "lodash"
import React from "react"
import ReactDOMServer from "react-dom/server"
import marked from "marked"
import { parseRawHtml as parse } from "../utils"

import {
  Button,
  Card,
  Container,
  Header,
  Icon,
  Image,
  Modal,
  Segment,
  Item,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/teaching-and-learning.md"


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => (

  <Container>

    <Segment basic style={{ marginTop: "4em", padding: "0em" }}>
      <Header as="h2" dividing>
        {content.introductionSection.title}
        <SocialShare config={content.introductionSection.social} float="right" />
      </Header>

      <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "3em" }}>
        {
          content.introductionSection.introduction &&
          parse(marked(content.introductionSection.introduction))
        }
      </Segment>
    </Segment>


    {
      content.booksSection &&
      <Segment basic id="books" style={{ marginTop: "4em", padding: "0em" }}>

        {
          content.booksSection && content.booksSection.title &&
          <Header as="h2" dividing>
            {content.booksSection.title}
            <SocialShare config={content.booksSection.social} float="right" />
          </Header>
        }

        {
          content.booksSection && content.booksSection.introduction &&
          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
            {parse(marked(content.booksSection.introduction))}
          </Segment>
        }

        {
          content.booksSection.freelyAvailableBooksSubsection &&
          <Segment padded style={{ marginTop: "3em" }}>

            {
              content.booksSection.freelyAvailableBooksSubsection && content.booksSection.freelyAvailableBooksSubsection.title &&
              <Header as="h3">
                {content.booksSection.freelyAvailableBooksSubsection.title}
              </Header>
            }

            {
              content.booksSection.freelyAvailableBooksSubsection && content.booksSection.freelyAvailableBooksSubsection.introduction &&
              <Segment basic style={{ padding: "0em", marginTop: "2em" }}>
                {parse(marked(content.booksSection.freelyAvailableBooksSubsection.introduction))}
              </Segment>
            }

            {
              content.booksSection.freelyAvailableBooksSubsection && content.booksSection.freelyAvailableBooksSubsection.books &&
              <Card.Group stackable itemsPerRow={4} doubling={false}
                style={{
                  marginTop: "2em",
                  maxHeight: "50em",
                  overflow: "auto",
                }}>
                {content.booksSection.freelyAvailableBooksSubsection.books.map((book, i) => (
                  <Card raised>
                    <Modal closeIcon={true} trigger={
                      <Image as="a" wrapped ui={false}
                        src={book.image}
                      />
                    }>
                      <Modal.Header>
                        {book.title}
                        {
                          book.referenceLink &&
                          <a href={book.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header>
                      <Modal.Content>
                        <Image bordered centered rounded size="medium"
                          src={book.image}
                          as="a" href={book.linkedFileSelfHosted || book.linkedFileSelfHosted || book.referenceLink}
                          target="_blank" style={{ marginTop: "1em" }}
                        />
                        <Modal.Description>
                          <Segment basic style={{ marginTop: "2em", paddingLeft: "0em" }}>
                            {parse(`
                              ${`
                                <p style="font-size: 1.1em;">
                                  <b>Author(s) :</b> ${book.authors || "Placeholder Author"}
                                </p>
                              `}
                              ${`
                                <p style="margin-top: 2em; font-size: 1.1em;">
                                  <b>Description :</b> ${marked(book.briefDescription)}
                                </p>
                              `}
                              ${`
                                <p style="margin-top: 2em; font-size: 1.1em;">
                                  <b>Publication Date :</b> ${book.publicationDate}
                                </p>
                              `}
                            `)}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  </Card>
                ))}
              </Card.Group>
            }

          </Segment>
        }

        {
          content.booksSection.additionalBooksSubsection &&
          <Segment padded style={{ marginTop: "3em" }}>

            {
              content.booksSection.additionalBooksSubsection && content.booksSection.additionalBooksSubsection.title &&
              <Header as="h3">
                {content.booksSection.additionalBooksSubsection.title}
              </Header>
            }

            {
              content.booksSection.additionalBooksSubsection && content.booksSection.additionalBooksSubsection.introduction &&
              <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
                {parse(marked(content.booksSection.additionalBooksSubsection.introduction))}
              </Segment>
            }

            {
              content.booksSection.additionalBooksSubsection && content.booksSection.additionalBooksSubsection.books &&
              <Card.Group stackable itemsPerRow={4} doubling={false}
                style={{
                  marginTop: "2em",
                  maxHeight: "50em",
                  overflow: "auto",
                }}>
                {content.booksSection.additionalBooksSubsection.books.map((book, i) => (
                  <Card raised>
                    <Modal closeIcon={true} trigger={
                      <Image as="a" wrapped ui={false}
                        src={book.image}
                      />
                    }>
                      <Modal.Header>
                        {book.title}
                        {
                          book.referenceLink &&
                          <a href={book.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                            <Icon size="small" name="external" />
                          </a>
                        }
                      </Modal.Header>
                      <Modal.Content>
                        <Image bordered centered rounded size="medium"
                          src={book.image}
                          as="a" href={book.linkedFileSelfHosted || book.linkedFileSelfHosted || book.referenceLink}
                          target="_blank" style={{ marginTop: "1em" }}
                        />
                        <Modal.Description>
                          <Segment basic style={{ marginTop: "2em", paddingLeft: "0em" }}>
                            {parse(`
                              ${`
                                <p style="font-size: 1.1em;">
                                  <b>Author(s) :</b> ${book.authors || "Placeholder Author"}
                                </p>
                              `}
                              ${`
                                <p style="margin-top: 2em; font-size: 1.1em;">
                                  <b>Description :</b> ${marked(book.briefDescription)}
                                </p>
                              `}
                              ${`
                                <p style="margin-top: 2em; font-size: 1.1em;">
                                  <b>Publication Date :</b> ${book.publicationDate}
                                </p>
                              `}
                            `)}
                          </Segment>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  </Card>
                ))}
              </Card.Group>
            }

          </Segment>
        }
      </Segment>
    }

    {
      content.classroomGuidesAndResourcesSection &&
      <Segment basic id="classroom-guides-and-resources" style={{ marginTop: "4em", padding: "0em" }}>

        {
          content.classroomGuidesAndResourcesSection && content.classroomGuidesAndResourcesSection.title &&
          <Header as="h2" dividing>
            {content.classroomGuidesAndResourcesSection.title}
            <SocialShare config={content.classroomGuidesAndResourcesSection.social} float="right" />
          </Header>
        }

        {
          content.classroomGuidesAndResourcesSection && content.classroomGuidesAndResourcesSection.introduction &&
          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
            {parse(marked(content.classroomGuidesAndResourcesSection.introduction))}
          </Segment>
        }

        {
          content.classroomGuidesAndResourcesSection && content.classroomGuidesAndResourcesSection.items &&
          <Item.Group divided relaxed style={{ marginTop: "3em" }}
            content={
              content.classroomGuidesAndResourcesSection.items.map((item, j) => (
                <Item
                  children={
                    <>
                      <a target="_blank"
                        style={{
                          textAlign: "center"
                        }}
                        href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                        children={
                          <Item.Image bordered spaced size="small"
                            src={item.image}
                          />
                        }
                      />
                      {
                        item.title && item.briefDescription &&
                        <Item.Content
                          style={{
                            marginTop: isMobile && "2em",
                            marginLeft: !isMobile && "1em",
                          }}
                          content={
                            <>
                              <Item.Header content={item.title} />
                              <Item.Description className="MkdContent"
                                content={
                                  parse(`
                                    ${marked(item.briefDescription)}
                                  `)
                                }
                              />
                              <Item.Extra
                                content={
                                  <Button primary as="a" target="_blank"
                                    href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                                    content="View"
                                  />
                                }
                              />
                            </>
                          }
                        />
                      }
                    </>
                  }
                />
              ))
            }
          />
        }

      </Segment>
    }

    {
      content.trainersGuidesAndResourcesSection &&
      <Segment basic id="trainers-guides-and-resources" style={{ marginTop: "4em", padding: "0em" }}>

        {
          content.trainersGuidesAndResourcesSection && content.trainersGuidesAndResourcesSection.title &&
          <Header as="h2" dividing>
            {content.trainersGuidesAndResourcesSection.title}
            <SocialShare config={content.trainersGuidesAndResourcesSection.social} float="right" />
          </Header>
        }

        {
          content.trainersGuidesAndResourcesSection.introduction &&
          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
            {parse(marked(content.trainersGuidesAndResourcesSection.introduction))}
          </Segment>
        }

        {
          content.trainersGuidesAndResourcesSection && content.trainersGuidesAndResourcesSection.items &&
          <Item.Group divided relaxed style={{ marginTop: "3em" }}
            content={
              content.trainersGuidesAndResourcesSection.items.map((item, j) => (
                <Item
                  children={
                    <>
                      <a target="_blank"
                        style={{
                          textAlign: "center"
                        }}
                        href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                        children={
                          <Item.Image bordered spaced size="small"
                            src={item.image}
                          />
                        }
                      />
                      {
                        item.title && item.briefDescription &&
                        <Item.Content
                          style={{
                            marginTop: isMobile && "2em",
                            marginLeft: !isMobile && "1em",
                          }}
                          content={
                            <>
                              <Item.Header content={item.title} />
                              <Item.Description className="MkdContent"
                                content={
                                  parse(`
                                    ${marked(item.briefDescription)}
                                  `)
                                }
                              />
                              <Item.Extra
                                content={
                                  <Button primary as="a" target="_blank"
                                    href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                                    content="Download"
                                  />
                                }
                              />
                            </>
                          }
                        />
                      }
                    </>
                  }
                />
              ))
            }
          />
        }

      </Segment>
    }

    {
      content.onlineLearningSection &&
      <Segment basic style={{ marginTop: "3em", padding: "0em" }}>

        {
          content.onlineLearningSection && content.onlineLearningSection.title &&
          <Header as="h3">
            {content.onlineLearningSection.title}
          </Header>
        }

        {
          content.onlineLearningSection && content.onlineLearningSection.introduction &&
          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
            {parse(marked(content.onlineLearningSection.introduction))}
          </Segment>
        }

        {
          content.onlineLearningSection && content.onlineLearningSection.items && content.onlineLearningSection.items.map((item, j) => (
            <Segment basic style={{ marginTop: "4em", padding: "0em" }}>
              <a target="_blank"
                href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                children={
                  <Image rounded wapped size="small" floated="left"
                    src={item.image}
                  />
                }
              />
              {
                item.briefDescription &&
                <Segment basic className="MkdContent" style={{ padding: "0em", margin: "0em", overflow: "hidden" }}>
                  {parse(`
                    ${ReactDOMServer.renderToStaticMarkup(
                    <Header as="h4" style={{ marginTop: "0em", padding: "0em" }}>
                      {item.title}
                    </Header>
                  )}
                    ${marked(item.briefDescription)}
                    ${ReactDOMServer.renderToStaticMarkup(
                    <Button primary as="a" target="_blank"
                      href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                      content="Download"
                    />
                  )}
                  `)}
                </Segment>
              }
            </Segment>
          ))
        }
      </Segment>
    }

  </Container>

)
